/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var drupalAnalyticsSettings = Drupal.settings.analytics ? Drupal.settings.analytics.analytics_tagging_enabled : false;
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
  var loyaltyEventSelectors = [];
  var eventObj = {};
  // Flag to avoid bubbling on alt image click
  var altImageClickCheck = false;
  var skuId = null;
  var slideNum = 0;
  var previousBgImageUrl = null;
  var previousDataSlickIndex = 0;
  var previousBgImageUrlMobile = null;
  var previousDataSlickIndexMobile = 0;

  Drupal.behaviors.analyticsBehavior = {

    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [0];
      var self = this;
      if (prodcat && prodcat.data && prodcat.data.pids) {
        positions = productIds.map(function(element) {
          return self.findElementIndex(prodcat.data.pids, element);
        });
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },
    signin: function(eventData) {
      var obj={};
      obj.userPreference = "Email";
      site.track.signin(Object.assign(obj, eventData));
    },
    registration: function(eventData) {
      var obj={};
      obj.userPreference = "Email";
      site.track.registration(Object.assign(obj, eventData));
    },

    liveChatManualInitiated: function(eventData) {
      var targetClass, nodeElement, contentName, obj;
      targetClass = $('#mpp_chat').attr('class') !== undefined && typeof $('#mpp_chat').attr('class') !== 'undefined' ? $('#mpp_chat') : '';
      if (targetClass !== '') {
        contentName = targetClass.closest('.node-elc-nodeblock').attr('trackname').replace(/\|.*/gi, '');
        nodeElement = targetClass.text().trim();
        obj = {
          event_action: contentName,
          event_category: 'Content Modules',
          event_label: nodeElement
        };
        site.track.evtLink(obj);
      }
      site.track.liveChatManualInitiated(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      var drupalAnalyticSettingsObj = Drupal.settings.analytics;
      var postSampleSelection = drupalAnalyticSettingsObj ? drupalAnalyticSettingsObj.post_sample_selection : false;
      var eventObj = {};
      if (self.attached) {
        return;
      }

      // Track Brand Logo
      $('a.page-branding__logo , .js-page-logo', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      //Track Login
      $(document).on('mouseenter', '.utility-nav__signin-text', function() {
        var obj = {
          event_name: 'account_overlay_open',
          event_category: 'account',
          event_action: 'overlay open',
          event_label: 'standard'
        };
        site.track.evtLink(obj);
      });

      // Search Icon click
      $('.page-utilities__search-icon').on('click', function() {
        var obj = {
          event_name: 'search click',
          event_category: 'search',
          event_action: 'click',
          event_label: 'click'
        };
        site.track.evtLink(obj);
      });

      // View Cart click
      $('.utility-nav__item.utility-nav__cart').on('click', function() {
        var obj = {
          event_name: 'view cart',
          event_category: 'cart',
          event_action: 'view',
          event_label: window.location.href
        };
        site.track.evtLink(obj);
      });

      // Live chat click
      $(document).on('click', 'a.udesk-client-btn', function() {
        var obj;
        var params = new URLSearchParams($('#wo_chatholder_wo').attr('src'));
        var chatid = params.get('session');
        obj = {
          event_name: 'liveperson chat start',
          event_category: 'liveperson text chat',
          event_action: 'chat start',
          event_label: chatid
        };
        site.track.evtLink(obj);
      });

      // SearchOverlay productClick start.
      $(document).on('SEARCHPRODUCTCLICK', function (e) {
        var $currentElem = $(e.target.activeElement);
        var prodId = $(e.target.activeElement).closest('.product-result').attr('data-product-id');
        var prodName = $(e.target.activeElement).closest('.product-result').find('.product-result__name').text().trim();
        var prodPosition;

        $('.typeahead-product-results .product-result').each(function (index) {
          $(this).attr('data-index', index);
        });
        prodPosition = $(e.target.activeElement).closest('.product-result').data('index') + 1;
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });
      // SearchOverlay productClick end.

      // Track Product Click
      var productClickElement = [
        '.product-brief a:first-child',
        '.product_brief__image-container',
        '.product_brief__headers',
        '.product_brief__full-details',
        '.product_brief__buttons',
        '.product-brief__button-quickshop',
        '.js-product-images-wrapper',
        '.elc-product-product-name-wrapper',
        '.elc-product-description-wrapper',
        '.js-product-brief .js-product-name-wrapper',
        '.search-page__products .search-product a',
        '.typeahead-product-results .product-result a'
      ];

      $(document).on('click', productClickElement.join(', '), function () {

        $('.elc-product-brief.js-product-brief, .search-page__products .search-product, .perlgem-search-block .product-result').each(function (index) {
          $(this).attr('data-index', index);
        });
        var $this = $(this);

        // don't track shade clicks
        if ($this.hasClass('swatch') || $this.hasClass('js-add-to-cart')) {
          return;
        }
        var prodElem = $this.closest('.js-product-brief, .product-brief, .js-discover-more-quickshop, .search-product, .product-result');
        var prodId = prodElem.attr('data-product-id') || 'PROD' + $this.closest(' .js-product-brief-wrapper').attr('data-product-id') || prodElem.attr('data-productid');
        var incStr = prodId.includes('PROD');

        if (!incStr) {
          prodId = 'PROD' + prodId;
        }

        var prodPosition = Number($this.closest('.js-product-brief, .search-product, .product-result').data('index')) + 1;
        var prodName = $this.closest('.js-product-brief, .search-product, .product-result').find('.js-product-display-name-wrapper, .search-product__header, .product-result__name').text().trim();

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $this,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });

      // Track Product Click in quickshop
      $(document).on('click', '.quickshop-inline__details, .recommended-products__section .js-quickshop, .recommended-product-items .js-quickshop', function () {
        var $currentElem = $(this);
        $('.recommended-product-items .sku-brief-editorial').each(function (index) {
          $(this).attr('data-index', index);
        });
        var prodElem = $(this).closest('.product-brief__quickshop-container, .sku-brief-editorial');
        var prodId = prodElem.attr('data-productid');
        var prodPosition = prodElem.data('index') + 1;
        var prodName = prodElem.find('.text-product-listing-names-main').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });

      // Product click on Recommendation section
      $('.recommended-products__list .sku-brief-editorial, .discover-more__products .discover_more__product').each(function (index) {
        $(this).attr('data-index', index);
      });
      recomProductSelectors = [
        '.recommended-item a.thumb',
        '.recommended-item .product_name a',
        '.recommended-item .produce_subname a',
        '.recommended-products__list .sku-brief-editorial a',
        '#works-well-with-wrapper .sku-brief-editorial a',
        '.recommended-product-items .sku-brief-editorial a',
        '.recommended-product-items a.thumb column',
        '.recommended-product-items product_name a',
        '.recommended-product-items .produce_subname a',
        '.discover_more__product .discover_more__product-url',
        '.discover_more__product a.discover_more__product-quickshop'
      ];

      $(document).on('click', recomProductSelectors.join(', '), function () {
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.recommended-item').find('.recommended-item__button').data('product-id');

        if (typeof prodId == 'undefined') {
          var $prodHref = $prodElem.attr('href');
          var splitprod = $prodHref.split('/');
          var prodId = 'PROD' + splitprod[3];
        }
        var prodPosition = Number($prodElem.closest('.sku-brief-editorial, .discover_more__product').data('index')) + 1;
        var prodName = $prodElem.closest('.sku-brief-editorial, .discover_more__product').find('.text-product-listing-names-main, .discover_more__product-header').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });

      // Track Quick Shop
      if (!$('body').hasClass('section-products')) {
        var productDetailElement = [
          'a.js-mpp_quickshop',
          '.product_brief__button-panel',
          '.js-quickshop-show',
          '.product-brief a:first-child'
        ];

        $(document).on('click', productDetailElement.join(', '), function (event) {
          var $targetElem = $(this);
          var position = 0;
          var catName = $('.main-content > article, .content > article', context).attr('trackname');
          var prodElem = $targetElem.closest('.js-product-brief,.product-brief, .js-product-brief-wrapper');
          var prodId = $targetElem.attr('data-product-id') || prodElem.attr('data-product-id');
          var prodHeader = prodElem.find('.product_brief__header, .product-brief__header, .js-product-display-name').text();
          var prodSubHeader = prodElem.find('.product_brief__sub-header, .product-brief__sub-header, .js-product-subheader').text();
          var prodName = prodHeader + ' ' + prodSubHeader;

          if (!prodId.includes('PROD')) {
            prodId = 'PROD' + prodId;
          }

          if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
            position = self.getProductPositions([prodId]);
          }

          var obj = {
            event_label: prodName + ' - ' + prodId,
            page_name: 'QV | ' + prodName,
            product_id: [prodId],
            product_catagory_name: [catName],
            product_price: [prodElem.find('.product_brief__price, .js-product-original-price').text().replace(/\s+/g, ' ').trim()]
          };

          if ($(event.target).hasClass('js-analytics-quickshop-home') || $(event.target).hasClass('js-quickshop-show') || $(event.target).hasClass('product_brief__button-panel') || $(event.target).hasClass('js-mpp_quickshop')) {
            site.track.quickView(obj);
          }
        });

        // Track Quick Shop 2 - Special case in EL where some pages don't have data-test-id=mpp_quickshop and for SPP and Viewcart quickshop
        var quickShopSpecialElem = [
          '.js-discover-more-quickshop',
          '.js-quickshop',
          '.discover_more__product-quickshop',
          '.js-quickshop-add-button'
        ];

        $(document).on('click', quickShopSpecialElem.join(', '), function () {
          var catName = $('.content > article', context).attr('trackname');
          var $prod = $(this);
          var prodElem = $prod.parent();
          var prodId = $prod.data('productid') || $prod.data('product-id');
          var prodName = prodElem.find('.discover_more__product-header').text() || prodElem.parent().find('.text-product-listing-names-main').text() || $prod.closest('.recommended-products__section').find('.sku-brief-editorial__product--name').text().trim();
          var position = 0;

          if ($prod.attr('type') && $prod.attr('type') === 'submit') {
            return;
          }

          if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
            position = self.getProductPositions([prodId]);
          }
          var obj = {
            event_label: prodName + ' - ' + prodId,
            page_name: 'QV | ' + prodName,
            product_id: [prodId],
            product_catagory_name: [catName],
            product_price: prodElem.find('.discover_more__product-price, .text--bold').text().replace(/\s+/g, ' ').trim(),
            product_position: position
          };
          site.track.quickView(obj);
        });
      }

      // Track Predictive Search Product Click and All Results Click
      $('#typeahead-wrapper').on('click', '.product-result', function() {
        var prods = $(this);
        var prodDetail = prods.find('.product-result__name a');
        var prodName = prodDetail.text().trim();
        var prodUrl = prodDetail.attr('href');
        var prodElemID = 'PROD' + prodUrl.split('/')[3];
        var term = $('input#search').val();
        var obj;
        event.preventDefault();
        self.setLinkCapture(prodUrl, '_self');
        obj = {
          event_label: term,
          search_keyword: term,
          product_id: prodElemID,
          product_name: prodName
        };
        site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
      });

      loyaltyEventSelectors = [
        '.js-account-index__loyalty__enrollment',
        '.js-checkout__loyalty__enrollment'
      ];
      $(document).on('click', loyaltyEventSelectors.join(', '), function() {
        var pageID = window.utag_data ? window.utag_data.page_name : ' ';
        site.track.loyaltyTag(pageID);
      });
      $(document).on('click', '.js-loyalty__panel__leave__submit', function() {
        site.track.loyaltyTag('cancel loyalty');
      });
      $(document).on('click', '.js-loyalty__panel__offers__button', function() {
        site.track.loyaltyTag('apply offers');
      });
      $(document).on('click', '.js-loyalty__panel__product__button', function() {
        site.track.loyaltyTag('redeem rewards');
      });
      $(document).on('click', '.js-home-page__enrollment', function() {
        site.track.loyaltyTag('enrollment overlay');
      });
      //join loyalty at create account
      $('.js-signup--loyalty_join_check').click(function() {
        if ($(this).is(':checked')) {
          $(document).on('click', '.js-create-account__loyalty', function() {
            var pageID = window.utag_data ? window.utag_data.page_name : ' ';
            site.track.loyaltyTag(pageID);
          });
        }
      });
      //update loyalty in registration
      $(document).on('click', '.js-select-registration__loyalty', function() {
        if ($(this).is(':checked')) {
          $(document).on('click', '.js-account-update__loyalty', function() {
            site.track.loyaltyTag('account');
          });
        }
      });
      // CHECKOUT EVENTS
      // Track guest user checkout
      var guestUserElemets = [
        '.js-analytics-new-user-input-submit',
        '#js_analytics_newuser_submit',
        '.new-account__submit',
        '#checkout_signin_new_user-submit',
        '#checkout_signin_guest_user-submit'
      ];
      $(document).on('click', guestUserElemets.join(', '), function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });
      // Track loyalty signup in order confirmation page
      $('.js-checkout__loyalty__register').on('click', function() {
        site.track.loyaltyTag('checkout signup');
      });
      // Track loyalty enrollment in order confirmation page
      $('.js-checkout__loyalty__join').on('click', function() {
        site.track.loyaltyTag('checkout enrollment');
      });
      //Track loyalty enrollment in checkout page
      $('.js-checkout__loyalty__select').on('click', function() {
        site.track.loyaltyTag('checkout enrollment');
      });
      // Track offer code handler in checkout page
      $('.js-offer-code-wallet__button--apply').on('click', function() {
        site.track.loyaltyTag('checkout apply offers');
      });
      // Track return user checkout
      var returnUserElements = [
        '.js-analytics-return-user-input-submit',
        '#js_analytics_return_user_submit',
        '.return-user__submit',
        '#checkout_signin-submit'
      ];
      $(document).on('click', returnUserElements.join(', '), function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Track Payment Method
      var paymentElements = [
        '#checkout_billing input.btn-primary',
        '#checkout_review input.button--dark',
        'a.checkout-buttons',
        'input.js-checkout-complete',
        '#continue-checkout-btm a.checkout-buttons',
        'input#continue-checkout',
        '.js-analytics-payment',
        '.js_analytics_checkout_payment',
        '.btn_continue_checkout',
        '#checkout_payment .js_analytics_payment',
        '#checkout_complete .js_analytics_payment'
      ];
      $(document).on('click', paymentElements.join(','), function() {
        var $targetElem = $(this);
        if ($targetElem.hasClass('btn_apply_cod_payment') && $targetElem.parents().hasClass('offer-code__content')) {
          return;
        }
        var paymentLabel = '';
        var paymentTypePP = [
          'PayPal',
          'PP',
          'PAYMENT_OPTION_PAYPAL',
          'PP_PAYPAL',
          'PP_Braintree'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'CC',
          'PAYMENT_OPTION_MC',
          'PAYMENT_OPTION_VISA',
          'PAYMENT_OPTION_MAESTRO',
          'PAYMENT_OPTION_AMEX',
          'PAYMENT_OPTION_SOFORT',
          'PAYMENT_OPTION_MYBANK',
          'PAYMENT_OPTION_CARDLINK',
          'PP_MPWEB',
          'GMO',
          'SecurePay',
          'PAYMENT_OPTION_PP',
          'PAYMENT_OPTION_CC',
          'Card',
          'Pelecard',
          'KBank',
          'PAYMENT_OPTION_NCCC_NORMAL',
          'PAYMENT_OPTION_NCCC_REDEMPTION'
        ];
        var paymentTypeCOD = [
          'COD',
          'PAYMENT_OPTION_COD'
        ];
        var paymentTypeUnionPay = [
          'UPOP'
        ];
        var paymentTypeAliPay = [
          'ALIPAY'
        ];
        var paymentTypeWechat = [
          'WECHAT'
        ];
        var paymentTypeAfterPay = [
          'AfterPay'
        ];
        var paymentTypeLinePay = [
          'PAYMENT_OPTION_LINEPAY'
        ];
        var paymentTypePayU = [
          'PAYMENT_OPTION_PAYU'
        ];
        var paymentType = $('input[name=PAYMENT_METHOD]:checked').val() || $('input[name=PAYMENT_TYPE]:checked').val() || $('input[name=PAYMENT_OPTION]:checked').val() || $('input[name=PP_NAME]:checked').val() || $('input[name=PP_PAYMENT_METHOD]:checked').val() || $('#form--checkout_payment_type--field--PAYMENT_OPTION').val() || $('input[name=payMethod]:checked').val() || $('select[name=PAYMENT_OPTION] option:selected').val();
        if ($.inArray(paymentType, paymentTypePP) > -1) {
          paymentLabel = 'paypal';
        } else if ($.inArray(paymentType, paymentTypeCC) > -1) {
          paymentLabel = 'creditcard';
        } else if ($.inArray(paymentType, paymentTypeCOD) > -1) {
          paymentLabel = 'cashondelivery';
        } else if ($.inArray(paymentType, paymentTypeUnionPay) > -1) {
          paymentLabel = 'UnionPay';
        } else if ($.inArray(paymentType, paymentTypeAliPay) > -1) {
          paymentLabel = 'AliPay';
        } else if ($.inArray(paymentType, paymentTypeWechat) > -1) {
          paymentLabel = 'Wechat';
        } else if ($.inArray(paymentType, paymentTypeAfterPay) > -1) {
          paymentLabel = 'AfterPay';
        } else if ($.inArray(paymentType, paymentTypeLinePay) > -1) {
          paymentLabel = 'LinePay';
        } else if ($.inArray(paymentType, paymentTypePayU) > -1) {
          paymentLabel = 'PayU';
        }
        var obj = {
          event_label: paymentLabel
        };
        if (paymentLabel.length !== 0) {
          site.track.evtAction('checkoutPaymentSelected', obj);
        }
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // END CHECKOUT EVENTS

      // MPP Exposed Shade Filter tracking
      $('#mpp_filter_dropdown_1_select,#mpp_filter_dropdown_2_select', context).on('change', function() {
        var shadeText, id, shadeTextDrop, obj;
        shadeText = $('option:selected', this).text();
        id = $(this).attr('id');
        shadeTextDrop = id === 'mpp_filter_dropdown_1_select' ? 'Dropdown-1' : 'Dropdown-2';
        if (shadeText !== '(Select Coverage)' && shadeText !== '(Select Finish)') {
          obj = {
            'event_category': 'filter & sort selection',
            'event_action': 'filter - MPP Product Filter',
            'event_label': shadeTextDrop + ' - ' + shadeText
          };
          site.track.evtLink(obj);
        }
      });

      //SPP Filter Interaction
      $('.js-spp-filter', context).on('click', function() {
        var obj, shadeText;
        shadeText = $(this).text();
        obj = {
          'event_category': 'filter & sort selection ',
          'event_action': 'filter - SPP Shade Filter',
          'event_label': shadeText
        };
        site.track.evtLink(obj);
      });

      $(document).on('change', '.js-product-sort', function () {
        var selectedElem = $(this).find('option:selected');
        var obj = {
          event_label: selectedElem.length > 0 ? selectedElem.text() : ''
        };

        site.track.evtAction('filterProducts', obj);
      });

      //Foundation Finder tracking
      $(window).on('load', function() {
        if ($('body').hasClass('foundation-finder-page') && drupalAnalyticsSettings) {
          trackFoundationFinder();
        }
      });

      function trackFoundationFinder() {
        var eventLabel, foundationId;

        $('.ff-quiz__slide', context).each(function(key) {
          $(this).attr('data-id', key);
        });

        function getfoundationName(findElement) {
          var $foundationDomElement, foundationName;
          $foundationDomElement = $('.ff-quiz__nav-item', context)[findElement];
          foundationName = $($foundationDomElement).find('.ff-quiz__nav-label').text().trim();
          return foundationName;
        }

        initFoundationFinder();

        function trackStartState() {
          var $foundationElement = $('.ff-quiz__slide--1', context);
          if ($foundationElement.hasClass('slick-active') || $foundationElement.hasClass('active')) {
            foundationId = $('.ff-quiz__slide--1', context).data('id');
            eventLabel = 'Initiated | ' + getfoundationName(foundationId);
            trackEventState('foundationfinder_start', eventLabel);
          }
        }

        function trackNextState() {
          $('.ff-quiz__button--next', context).on('click', function() {
            var $foundationButtonElement = $(this);
            if ($foundationButtonElement.is('.ff-quiz__button--results') || $foundationButtonElement.is('.active') && $foundationButtonElement.children().is('.ff-quiz__button-text--results')) {
              trackEventState('foundationfinder_complete', 'Completed');
            }
            $('.ff-quiz__slide', context).each(function() {
              var $foundationSlideElement, foundationStep;
              $foundationSlideElement = $(this);
              if (($foundationSlideElement.is('.slick-active') || $foundationSlideElement.is('.active')) && !$foundationButtonElement.is('.ff-quiz__button--results')) {
                foundationId = $foundationSlideElement.data('id');
                foundationStep = 'foundationStep_' + (foundationId + 1);
                eventLabel = 'Initiated | ' + getfoundationName(foundationId);
                trackEventState(foundationStep, eventLabel);
              }
            });
          });
        }

        function trackStartOver() {
          $('.ff-quiz__start-over-link', context).on('click', function() {
            foundationId = $('.ff-quiz__slide.slick-active', context).data('id');
            eventLabel = 'Initiated | ' + getfoundationName(foundationId);
            trackEventState('foundationfinder_start', eventLabel);
          });
        }

        function trackEventState(name, label) {
          var obj = {
            event_name: name,
            event_category: 'foundation finder',
            event_action: 'diagnostic',
            event_label: label
          };
          site.track.evtLink(obj);
        }

        function initFoundationFinder() {
          trackStartState();
          trackNextState();
          trackStartOver();
        }
      }

      //Moisturizer Quiz Tagging start
      var $moisturizerQuiz = $('body').find('.moisturizer-repromote-quiz');
      if ($('body').hasClass('section-skin-care') || $moisturizerQuiz.length) {
        trackMoisturizerTagging();
      }

      function trackMoisturizerTagging() {
        $('.js-quiz-question', context).each(function(key) {
          $(this).attr('data-id', key);
        });

        var quizStep;
        initMoisturizerTagging();

        function trackQuizStartTagging() {
          $(document).on('click', '.js-btn-init-quiz', function() {
            trackEvent('diagtools', 'diagnostic tool - moisturizer quiz', 'start button', 'click');
            trackNextPage('/skin-care/moisturizer/step1', '/skin-care/moisturizer/step1', 'Skin-care/Moisturizer - Step 1');
          });
        }

        function trackQuiztagging() {
          $('.js-panel-quiz .js-btn-next', context).once('js-Quiz-tagging').each(function() {
            var $quizElem = $(this);
            $quizElem.on('click', function() {
              var $quizParentElem = $quizElem.closest('.js-panel-quiz');
              var quiztitle = $quizParentElem.find('.mr-question--current .mr-question__question').text().trim();
              var quizval = $quizParentElem.find('.mr-question--current .mr-question__option--selected').text();
              var eventLabel = quiztitle + ' - ' + quizval;
              quizStep = $quizParentElem.find('.mr-question--current').data('id');
              if (quizval) {
                trackEvent('diagtools', 'diagnostic tool - moisturizer quiz', 'questions', eventLabel);
                if ($quizElem.text().trim() !== 'Show Results') {
                  trackNextPage('/skin-care/moisturizer/step' + (quizStep + 2), '/skin-care/moisturizer/step' + (quizStep + 2), 'Skin-care/Moisturizer - Step ' + (quizStep + 2));
                }
              }
            });
          });
        }

        function trackQuizBackTagging() {
          $('.mr-actions__back', context).once('js-quiz-back').each(function() {
            var $quizBackElem = $(this);
            $quizBackElem.on('click', function() {
              var backStep = $('.js-btn-back').closest('.js-panel-quiz').find('.mr-question--current').data('id');
              trackEvent('diagtools', 'diagnostic tool - moisturizer quiz', 'back button', 'click');
              trackNextPage('/skin-care/moisturizer/step' + backStep, '/skin-care/moisturizer/step' + backStep, 'Skin-care/Moisturizer - Step ' + backStep);
            });
          });
        }

        function trackQuizRestartTagging() {
          $('.js-btn-restart', context).once('js-quiz-restart').each(function() {
            var $quizRestartElem = $(this);
            $quizRestartElem.on('click', function() {
              trackEvent('diagtools', 'diagnostic tool - moisturizer quiz', 'start over', 'click');
              trackNextPage('/skin-care/moisturizer/', '/skin-care/moisturizer/', 'Skin-care/Moisturizer - start button');
            });
          });
        }

        function trackOverlayClose() {
          $(document).on('click', '.mr-results .js-btn-close-results', function() {
            var currentStep = $('.js-btn-back').closest('.js-panel-quiz').find('.mr-question--current').data('id');
            trackEvent('diagtools', 'diagnostic tool - moisturizer quiz', 'overlay', 'closed');
            trackNextPage('/skin-care/moisturizer/step' + (currentStep + 1), '/skin-care/moisturizer/step' + (currentStep + 1), 'Skin-care/Moisturizer - Step ' + (currentStep + 1));
          });
        }

        function tracktestTagging() {
          $(document).on('Quizresult', function() {
            var $resultItems = $('.js-panel-results');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $resultItems.find('.mr-quiz-result-v1').each(function(index, elem) {
              if (!$(elem).hasClass('slick-cloned')) {
                if (!$(elem).length) {
                  return;
                }
                $elem = $(elem);
                productImpressionIds.push($elem.attr('data-product-val'));
                productPositions.push(($elem.attr('data-slick-index') * 1) + 1);
                productImpressionList.push(location.pathname + '/results');
              }
            });

            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results',
              page_url: '/skin-care/moisturizer/results',
              page_name: 'Skin-care/Moisturizer - Results'
            };
            site.track.evtView(objView);
          });
        }

        function trackProductResult() {
          $(document).on('click', '.mr-result__cta', function() {
            var prodElem = $(this).closest('.mr-quiz-result-v1.slick-active');
            var productId = prodElem.attr('data-product-val');
            var productPosition = prodElem.attr('menu-order');
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [productPosition];
            eventObj.product_id = [productId];
            eventObj.product_list = [location.pathname + '/results'],
            trackEvent('diagtools', 'ecommerce', 'product click', 'product click', eventObj);
          });
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function initMoisturizerTagging() {
          trackQuizStartTagging();
          trackQuiztagging();
          trackQuizBackTagging();
          trackQuizRestartTagging();
          trackProductResult();
          trackOverlayClose();
          trackNextPage();
          tracktestTagging();
        }
      }

      //Moisturizer Quiz Tagging end.

      //Moisturizer Myth Tagging start
      if ($('body').hasClass('section-skin-care') && drupalAnalyticsSettings) {
        $('.js-btn-true-false', context).once('js-myth').each(function() {
          var $mythElem = $(this);
          $mythElem.on('click', function() {
            var $trackNameElem = $mythElem.closest('.js-product-grid__tout');
            var trackName = $trackNameElem.children('div').attr('trackname');
            var eventLabel = trackName.split('|')[0];
            var obj = {
              event_name: 'content modules myth',
              event_category: 'content modules',
              event_action: 'Myth vs Reality',
              event_label: eventLabel
            };
            site.track.evtLink(obj);
          });
        });
      }
      //Moisturizer Myth Tagging end

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Update the SKUID of selected shade in shade picker
        $(document).on('click', '.swatches--single, .swatch--selected', function() {
          var selectedSkuId = $('.swatch--selected.selected').data('sku-base-id');
          if (selectedSkuId !== skuId) {
            skuId = selectedSkuId;
          }
          resetDragEventActionFlags($(this));
        });
        // Update the SKUID of selected shade in shades dropdown
        $(document).on('change', '.js-sku-menu', function(event, data) {
          if (!(data && data.pageLoadTrigger)) {
            var selectedSkuId = $('.swatch--selected.selected').data('sku-base-id');
            if (selectedSkuId !== skuId) {
              skuId = selectedSkuId;
            }
          }
          resetDragEventActionFlags($(this));
        });
        // Trigger Alt image event only when alternate images are available
        if ($('body').hasClass('device-pc') && $('.product-full__images .slick-dots li').length > 1) {
          trackAltImageTrackingClickPC();
          trackAltImageTrackingDragPC();
        } else if ($('body').hasClass('device-mobile')) {
          trackAltImageTrackingMobile();
        }
      }

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        $(document).on('click', '.js-product-image-thumb, .product-full__image__thumb', function() {
          altImageClickCheck = true;
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.css('background-image');
          var altImageUrl = bgImageUrl.replace('url(', '').replace(')', '').replace(/\"/gi, '');
          if (altImageUrl) {
            altImageName = buildAltImageFileName(altImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
        });
      }

      // Track Alt image drag - desktop
      function trackAltImageTrackingDragPC() {
        $(document).on('afterChange', '.product-full__images', function(event, slick, currentSlide) {
          var tempSkuId = $('.swatch--selected.selected').data('sku-base-id');
          if (!altImageClickCheck && ($('.js-shade-picker .swatches--single').length > 1) && skuId !== tempSkuId) {
            slideNum = skuId !== null ? 1 : 0;
            skuId = tempSkuId;
          }
          if (!altImageClickCheck && slideNum !== currentSlide) {
            var $targetElem = $(this);
            slideNum = currentSlide;
            var altImageName = '';
            var prodDetails = buildAltImageProductName($targetElem);
            var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
            var currentDataSlickIndex = $targetElem.find('.slick-current').attr('data-slick-index');
            if (bgImageUrl) {
              altImageName = buildAltImageFileName(bgImageUrl);
            }
            if (previousBgImageUrl !== bgImageUrl && previousDataSlickIndex !== currentDataSlickIndex) {
              trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'drag');
              previousBgImageUrl = bgImageUrl;
              previousDataSlickIndex = currentDataSlickIndex;
            }
          } else if (altImageClickCheck) {
            slideNum = currentSlide;
          }
          altImageClickCheck = false;
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(document).on('afterChange', '.product-full__images, .spp-product__images', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
          var currentDataSlickIndex = parseInt($targetElem.find('.slick-current').attr('data-slick-index'));
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          if (previousBgImageUrlMobile !== bgImageUrl && previousDataSlickIndexMobile !== currentDataSlickIndex) {
            trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
            previousBgImageUrlMobile = bgImageUrl;
            previousDataSlickIndexMobile = currentDataSlickIndex;
          }
        });
      }
      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-pr-product');
        var prodId = prodElem.attr('data-product-id');
        var prodHeaderSelectors = '.js-prod-header, .product-full__title, .spp-product__header';
        var prodElemParent = prodElem.parent('.js-pr-prod-fullimg-cont').parent('.js-pr-prod-full');
        var prodHeader = prodElem.find(prodHeaderSelectors).text() || prodElemParent.find(prodHeaderSelectors).text();
        var prodSubHeaderSelectors = '.js-prod-sub-header, .spp-product__sub-header, .product-full__subtitle';
        var prodSubHeader = prodElem.find(prodSubHeaderSelectors).text() || prodElemParent.find(prodSubHeaderSelectors).text();
        if (!prodHeader && !prodSubHeader) {
          prodElem = targetElem.closest('.js-spp-product__image-container');
          prodHeader = prodElem.siblings('.js-product-full-description').find('.js-prod-header').text();
          prodSubHeader = prodElem.siblings('.js-product-full-description').find('.js-prod-sub-header').text();
        }
        var prodName = (prodHeader + ' ' + prodSubHeader).trim();
        if (prodName) {
          prodName = prodName.replace(/^\s+|\s+$/g, '');
        }
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Reset PC & Mobile site drag or swipe event action flags
      function resetDragEventActionFlags(targetElem) {
        previousBgImageUrlMobile = null;
        previousDataSlickIndexMobile = 0;
        var currentDataSlickIndex = targetElem.closest('.js-product-full-description').siblings('.js-pr-prod-fullimg-cont, .product-full__image-container').find('.slick-current').attr('data-slick-index');
        previousBgImageUrl = null;
        if (currentDataSlickIndex !== '0') {
          previousDataSlickIndex = 0;
        } else {
          previousDataSlickIndex = currentDataSlickIndex.toString();
        }
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Track CTAs clicks based on markup
      $('.cta-track').click(function() {
        if (typeof $(this).data('event_name') !== 'undefined' && typeof $(this).data('event_category') !== 'undefined') {
          site.track.evtLink({
            event_name: $(this).data('event_name'),
            event_category: $(this).data('event_category'),
            event_action: window.location.pathname,
            event_label: 'click',
          });
        }
      });

      // Track ANR Sample Clicks
      $(document).on('click', '#ollp_20200825_01, #ollp_20200825_02, #ollp_20200825_03', function() {
        var bannerHrefElem = $(this).find('a').attr('href');
        var eventLabel;
        if ($(this).attr('id') === 'ollp_20200825_03') {
          eventLabel = bannerHrefElem;
        } else {
          eventLabel = location.origin + location.pathname + bannerHrefElem;
        }
        trackEvent('offsiteLink', 'offsite link', 'click', eventLabel);
      });

      // EL Foundation finder start.
      if ($('body').hasClass('section-foundation-finder')) {
        trackEsteeFinderTagging();
      }

      function trackEsteeFinderTagging() {
        initEsteeFinderTagging();

        function foundationNextTagging() {
          $(document).on('Next', function(event, data) {
            var quizNav = $('.js-foundation-finder--quiz__nav-item.expended').find('.foundation-finder--quiz__nav-label').text().trim();
            var quizNavSplit = quizNav.split(' ');
            var quizNavLabel = quizNavSplit[1];
            var quizNavValue = $('.js-foundation-finder--quiz__nav-item.expended').find('.js-foundation-finder--quiz__nav-value').text().trim();
            var quizQuestionKey = $('.js-foundation-finder--quiz-slide.slick-active .js-foundation-finder--quiz-question').data('question-key') || quizNavLabel;
            var quizAnswer = $('.js-foundation-finder--quiz-button--next').closest('.js-foundation-finder--quiz').find('.js-foundation-finder--quiz__carousel-wrapper .slick-active .js-foundation-finder--quiz-answer.selected-answer').data('translated-answer') || quizNavValue;
            var quizmobStepIndex = $('.js-foundation-finder--quiz__nav-item.expended').data('step-index') + 2;
            var stepIndex = $('.js-foundation-finder--quiz-slide.slick-active').data('question-index') + 2 || quizmobStepIndex;
            var eventLabel = quizQuestionKey + ' - ' + quizAnswer;
            var mobEventLabel = quizQuestionKey || quizNavLabel + ' - ' + quizAnswer || quizNavValue;
            if (quizAnswer) {
              trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'questions', eventLabel);
              if (stepIndex !== 6 && !$('div').hasClass('results-loaded')) {
                trackNextPage('/foundation-finder/step' + stepIndex, '/foundation-finder/step' + stepIndex, 'foundation-finder - Step ' + stepIndex);
              }
            }
          });
        }

        function trackbreadcrumTagging() {
          $(document).on('click', '.js-foundation-finder--quiz__nav-item', function() {
            var stepCount = $(this).data('step-index') + 1;
            if (!$(this).hasClass('disabled')) {
              trackNextPage('/foundation-finder/step' + stepCount, '/foundation-finder/step' + stepCount, 'foundation-finder - Step ' + stepCount);
            }
          });
        }

        function trackProductDetails() {
          $(document).on('click', '.ff-results__product .sku-brief-editorial__image-wrapper, .sku-brief-editorial .text-product-listing-names-main, .sku-brief-editorial .text-product-listing-names-sub, .sku-brief-editorial .product_brief__desc1', function() {
            var finderProductId = $(this).closest('.sku-brief-editorial').data('productid');
            var finderProductPosition = $(this).closest('.slick-slide').data('slick-index') + 1;
            var finderProductName = $(this).closest('.sku-brief-editorial').find('.text-product-listing-names-main').text().trim();
            var eventLable = finderProductName + '-' + finderProductId;
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [finderProductPosition];
            eventObj.product_list = [location.pathname + '/results'];
            eventObj.product_id = [finderProductId];
            trackEvent('diagtools', 'ecommerce', 'product click', eventLable, eventObj);
          });
        }

        function trackAddToBag() {
          $(document).on('click', '.ff-results__product .js-add-to-cart', function() {
            var finderProductId = $(this).closest('.ff-results__product').data('productid');
            var finderProductPosition = $(this).closest('.ff-results__product').data('slick-index') + 1;
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [finderProductPosition];
            eventObj.product_list = [location.pathname + '/results'];
            eventObj.product_id = [finderProductId];
            trackEvent('diagtools', 'ecommerce', 'product click', 'product click', eventObj);
            trackAddtoBagPage(finderProductId);
          });
        }

        function resultPageTagging() {
          $(document).on('filterResult', function() {
            var $menuItems = $('.js-ff-results-recommended-products');
            var $recommendedItems = $('.spp__steps');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.ff-results__product').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-productid'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/results');
            });

            $recommendedItems.find('.quickshop__add-button').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-productid'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/also_recommended');
            });
            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results',
              page_url: '/foundation-finder/result',
              page_name: 'foundation-finder - result'
            };
            site.track.evtView(objView);
          });
        }

        function trackRequiz() {
          $(document).on('click', '.js-foundation-finder--quiz__start-over, .js-launch-account--return', function() {
            var eventLable = $(this).text().trim();
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', eventLable, 'Click');
            if ($(this).hasClass('js-foundation-finder--quiz__start-over')) {
              trackNextPage('/foundation-finder/step1', '/foundation-finder/step1', 'foundation-finder - Step 1');
            }
          });
        }

        function trackSaveProfileRequiz() {
          $(document).on('touchend', '.ff-results__link-item .js-launch-account--return', function() {
            var eventLable = $(this).text().trim();
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', eventLable, 'Click');
          });
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function trackAddtoBagPage(productId) {
          var objView = {
            enh_action: 'detail',
            product_id: [productId]
          };
          site.track.evtView(objView);
        }

        function initEsteeFinderTagging() {
          foundationNextTagging();
          trackbreadcrumTagging();
          resultPageTagging();
          trackProductDetails();
          trackRequiz();
          trackSaveProfileRequiz();
          trackAddToBag();
        }
      }
      // EL Foundation finder end.

      // Skincare Diagnostic Quiz Tagging start.
      if ($('body').hasClass('section-skincare-quiz')) {
        trackSkinDiagnosticTagging();
      }

      function trackSkinDiagnosticTagging() {
        initSkinDiagnosticTagging();

        function foundationstartTagging() {
          $(document).on('SkincareNext', function(event, data) {
            var stepCount = $('.js-mantle-cta-button').closest('.js-form-formatter__item.active').data('field-order') + 1;
            var quizQuestion = $('.js-mantle-cta-button').closest('.js-form-formatter__item.active').data('field-name');
            var quizAnswerLength = $('.js-form-formatter__item.active').find('.has-selected-value').length;
            var quizAnswer = '';
            $('.js-mantle-cta-button').closest('.js-form-formatter__item.active').find('.js-mantle-form__checkbox.active .js-adpl-active').each(function(index, elem) {
              var quizName = $(this).attr('placeholder');
              quizAnswer += quizName + ',';
            });
            var quizSlice = quizAnswer.slice(-1);
            if (quizSlice === ',') {
              quizAnswer = quizAnswer.slice(0, -1);
            }
            var eventLabel = quizQuestion + ' - ' + quizAnswer;
            if (stepCount === 1) {
              trackEvent('diagtools', 'diagnostic tool - skin quiz', 'start button', 'click');
              trackNextPage('/skincare-quiz/step' + stepCount, '/step_' + stepCount, 'skincare-quiz - Step ' + stepCount);
            } else if (quizAnswer && quizAnswerLength === 1) {
              trackEvent('diagtools', 'diagnostic tool - skin quiz', 'questions', eventLabel);
            }
            if (stepCount!==1 && (stepCount !== 4 && quizAnswer || quizAnswerLength === 0)) {
              trackNextPage('/skincare-quiz/step' + stepCount, '/step_' + stepCount, 'skincare-quiz - Step ' + stepCount);
            }
          });
        }

        function trackNavTagging() {
          $(document).on('click', '.js-form-indicator-menu .js-form-indicator.active', function() {
            var stepCount = $(this).closest('.js-form-formatter').data('current-step');
            trackNextPage('/skincare-quiz/step' + stepCount, '/step_' + stepCount, 'skincare-quiz - Step ' + stepCount);
          });
        }

        function trackBackButton() {
          $(document).on('SkincareBack', function(event, data) {
            var currentStep = data.new_order;
            trackEvent('diagtools', 'diagnostic tool - skin quiz', 'back button', 'click');
            trackNextPage('/skincare-quiz/step' + currentStep, '/step_' + currentStep, 'skincare-quiz - Step ' + currentStep);
          });
        }

        function resultPageTagging() {
          $(document).on('filterResult', function(event, data) {
            var $menuItems = $(data.html);
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.skin-finder-results__product-wrapper .js-product-brief').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/results');
            });

            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results',
              page_url: '/skincare-quiz/results',
              page_name: 'skincare-quiz - results'
            };
            site.track.evtView(objView);
            objView = {};
          });
        }

        function trackRequiz() {
          $(document).on('click', '.js-mantle-form-reset, .js-launch-account--return', function() {
            var eventLable = $(this).text().trim();
            trackEvent('diagtools', 'diagnostic tool - Skin quiz', eventLable, 'Click');
            if ($(this).hasClass('js-mantle-form-reset')) {
              trackNextPage('/skincare-quiz/step1', '/skincare-quiz/step1', 'skincare-quiz - Step 1');
            }
          });
        }

        function trackProductClick() {
          $(document).on('click', '.js-pr-product .product-brief__image, .product-brief__headers', function() {
            var quizProductId = $(this).data('product-id') || $(this).closest('.js-product-brief').data('product-id');
            var quiztPosition = $(this).closest('.js-product').data('slick-index') + 1;
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [quiztPosition];
            eventObj.product_list = [location.pathname + '/results'];
            eventObj.product_id = [quizProductId];
            trackEvent('diagtools', 'ecommerce', 'product click', 'product click', eventObj);
          });
        }

        function trackQuickShop() {
          $(document).on('click', 'span.quickshop__add-button', function() {
            var quizProductId = $(this).data('product-id');
            var quiztPosition = $(this).closest('.js-product').data('slick-index') + 1;
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [quiztPosition];
            eventObj.product_list = [location.pathname + '/results'];
            eventObj.product_id = [quizProductId];
            trackEvent('diagtools', 'ecommerce', 'product click', 'product click', eventObj);
            trackAddtoBagPage(quizProductId);
          });
        }

        function trackAddtoBagPage(productId) {
          var objView = {
            enh_action: 'detail',
            product_id: [productId]
          };
          site.track.evtView(objView);
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + location.pathname + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function initSkinDiagnosticTagging() {
          foundationstartTagging();
          trackNavTagging();
          trackBackButton();
          resultPageTagging();
          trackRequiz();
          trackProductClick();
          trackQuickShop();
          trackAddtoBagPage();
        }
      }
      // Skincare Diagnostic Quiz Tagging end.

      // Estee cart cta Tagging start.
      if ($('div').hasClass('js-viewcart-shortcuts-panel') || $('div').hasClass('js-account-login-button')) {
        $(document).on('click', '.js-account-login-button, .js-shortcuts-samples, .js-shortcuts-offers', function() {
          var eventLabel = $(this).text().trim();
          trackEvent('cart click', 'cart page', 'click', eventLabel);
        });

      }
      // Estee cart cta Tagging end.


      // Cart page tagging start - Tracking gift wrap.
      $(document).on('click', 'div.js-save-gift-options', function () {
        var data = site.track.refreshData() || {};
        trackEvent('gift wrap', 'ecommerce', 'gift wrap - cart', data.cart_product_id);
      });

      // Tracking need help links.
      $(document).on('click', '.links-panel-content__wrapper--text a', function () {
        trackEvent('click', 'ecommerce', 'cart', 'need-help - ' + $(this).text().trim());
      });

      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-gift-options-title, .js-promo-bca-title, .checkout__panel__title, .js-samples-title', function () {
        var clickSense = $(this).hasClass('open') ? $(this).hasClass('open') : '';
        var evtLabel = $(this).text().trim();
        if (clickSense) {
          trackEvent('checkout module close', 'ecommerce', 'module close', evtLabel);
        } else {
          trackEvent('checkout module open', 'ecommerce', 'module open', evtLabel);
        }
      });

      if (site?.elcEvents) {
        // Tacking rewards view
        site.elcEvents.addListener('tealium:loaded', function () {
          if (document.URL.indexOf('cart') > -1) {
            $(window).on('scroll.viewport', function () {
              var moduleView = document.querySelector('.checkout__offer-code__wallet-item__header');
              if (isInViewport(moduleView) && !$(moduleView).hasClass('js-viewport-fire')) {
                $(moduleView).addClass('js-viewport-fire');
                eventObj.promo_pos = ['module'];
                eventObj.promo_name = [$(moduleView).closest('.checkout__panel-content').prev('.checkout__panel__header').find('.checkout__panel__title').text().trim()];
                eventObj.promo_creative = ['View Cart Page'];
                eventObj.promo_id = ['module-View Cart Page-' + eventObj.promo_name];
                site.track.evtView(eventObj);
                $(window).off('scroll.viewport');
              }
            });
          }
        });

        // Tacking samples view
        site.elcEvents.addListener('tealium:loaded', function () {
          if (document.URL.indexOf('cart') > -1) {
            $(window).on('scroll.viewport', function () {
              var moduleCheck = document.querySelector('.samples-panel__samples-note');
              if (isInViewport(moduleCheck) && !$(moduleCheck).hasClass('js-viewport-fired')) {
                $(moduleCheck).addClass('js-viewport-fired');
                eventObj.promo_pos = ['module'];
                eventObj.promo_name = [$('.js-samples-title').text().trim()];
                eventObj.promo_creative = ['View Cart Page'];
                eventObj.promo_id = ['module-View Cart Page-' + eventObj.promo_name];
                site.track.evtView(eventObj);
                $(window).off('scroll.viewport');
              }
            });
          }
        });
      }

      // Tracking paypal click.
      $(document).on('click', '.paypal-checkout-btn', function () {
        trackEvent('paypal', 'ecommerce', 'checkout option', 'paypal');
      });
      // Cart page tagging end.

	  // Sigin Tag
      var signin = Drupal.settings.analytics ? Drupal.settings.analytics.signin_sitespecific_enabled : false;
      if (signin && site.elcEvents) {
        site.elcEvents.addListener('tealium:loaded', function() {
          trackRegister();
        });
      }
      function trackRegister() {
        $(document).on('SMS_OTP.success', function(evt, d) {
          if (d.is_return_user && site.trackingDataLayer.data.signed_in == 1) {
              var eventObj = {};
             eventObj.userPreference = "Phone";
            trackEvent('signin', 'account', 'login success', 'standard', eventObj);

          } else {
            if(site.trackingDataLayer.data.signed_in == 1)
             var eventObj = {};
             eventObj.userPreference = 'Phone';
            trackEvent('signin', 'account', 'create success', 'standard',eventObj);
          }
        });
      }

      // End Signin Tagging

      // Blog page Tagging start.
      if ($('body').hasClass('section-discover') || $('div').hasClass('js-discover-article')) {
        $(document).on('click', '.js-discover-navigation .discover-navigation__menu li a', function () {
          var eventLabel = $(this).text().trim();

          trackEvent('blog', 'blog', 'blog category selection', eventLabel);
        });

        $(document).on('click', '.js-discover-block__container', function (event) {
          var $blogImageElem = $(this);
          var eventLabel = $blogImageElem.find('.discover-block__url').attr('href');
          var blogSubtitle = $blogImageElem.find('.discover-block__teaser').text().trim();
          var blogDiscription = blogSubtitle.split(' ');

          eventObj.content_title = $blogImageElem.find('.discover-block__header').text().trim();
          eventObj.content_author = 'null';
          eventObj.content_category = 'null';
          eventObj.content_subcategory = 'null';
          eventObj.content_word_count = blogDiscription.length;
          if ($(event.target).hasClass('discover-block__url-usegradient')) {
            trackEvent('blog', 'blog', 'blog article selection', eventLabel, eventObj);
          }
        });

        window.onload = function () {
          if ($('div').hasClass('js-product-brief-carousel-items')) {
            var $menuItems = $('.slick-list');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;

            $menuItems.find('.slick-slide').each(function (index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.find('.mpp__product').attr('data-product-id'));
              productPositions.push(index);
              productImpressionList.push('/blog list');
            });

            var objView = {
              event_name: 'blog',
              event_category: 'ecommerce',
              event_action: 'product carousel display',
              event_label: 'product carousel display',
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: window.location.href
            };

            site.track.evtLink(objView);
            if ($('div').hasClass('basic-img__wrapper')) {
              var trackName = $('article').attr('trackname');
              var trackSplit = trackName.split(':');
              var tracksection = trackSplit[1].split('|');
              var promoCreative = tracksection[0];
              var promoName = promoCreative;

              site.track.evtView({
                promo_pos: ['module'],
                promo_creative: [promoCreative],
                promo_name: [promoCreative],
                promo_id: ['module - ' + promoCreative + ' - ' + promoName]
              });
            }
          }
        };

        $(document).on('click', '.carousel-controls .slick-arrow', function () {
          var $slickElem = $('.slick-list .slick-active');
          var prodArray = [];

          $slickElem.each(function () {
            prodArray.push($(this).find('.js-product-brief').attr('data-product-id'));
          });

          var eventLabel = prodArray.join('|');

          trackEvent('blog', 'blog', 'blog product carousel - arrow click', eventLabel);
        });

        $(document).on('click', '.js-product-brief', function () {
          var $blogImageElem = $(this);
          var blogResultId = $blogImageElem.data('product-id');
          var blogProdPosition = parseInt($blogImageElem.closest('.slick-slide').attr('data-slick-index'));

          eventObj.enh_action = 'product_click';
          eventObj.product_position = [blogProdPosition];
          eventObj.product_list = ['blog list'];
          eventObj.product_id = ['PROD' + blogResultId];
          trackEvent('blog', 'ecommerce', 'product click', 'product click', eventObj);
        });

        $(document).on('click', '.js-discover-block__subtags a', function () {
          var eventLabel = $(this).attr('href');

          trackEvent('blog', 'blog', 'blog - link click', eventLabel);
        });

        $('.js-blog-article__social-share ul li a, .js-blog-article__social--pinterest .pinterest .lazyloaded').on('click', function () {
          var eventLabel = $(this).closest('li').attr('class') || $(this).closest('.pinterest').attr('class');

          trackEvent('social share', 'social', 'share - blog page', eventLabel);
        });
      }
      // Blog page Tagging end.

      // Track Product Click in findation finder - how to user section
      $('.js-spp_howtouse-product').on('click', '.js-quickshop-add-button', function () {
        var $this = $(this);
        var $productElem = $this.closest('.js-spp-howtouse');
        var prodId = $this.attr('data-productid');
        var skuID = $this.attr('data-sku-base-id');
        var prodName = $productElem.find('.js-product-listing-names-main').text().trim();
        var prodPrice = $productElem.find('.js-product-price').text().trim();

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.evtLink({
            event_name: 'product_click',
            event_category: 'foundation_finder',
            event_action: 'product clicked',
            event_label: prodId + ' - ' + prodName,
            product_sku: skuID,
            product_price: prodPrice
          });
        }
      });

      // Event track common function call start
      function trackEvent(eName, eCategory, eAction, elabel, eventObj) {
        eventObj = eventObj || {};
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
      }
      // Event track common function call end

      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-toggle-link-title, .samples__header-text', function () {
        var $currentElem = $(this);
        var $samplePanel = $currentElem.closest('#samples-panel');
        var $samplePanelContentElem = $samplePanel.find('.samples-panel__content');
        var clickSense = $samplePanelContentElem.length ? $samplePanelContentElem.is(':visible') : $samplePanel.find('.samples__content').is(':visible');
        var evtLabel = $currentElem.text().trim();

        if (clickSense) {
          trackEvent('sample_module_close', 'samples', 'module_close', evtLabel);
        } else {
          trackEvent('sample_module_open', 'samples', 'module_open', evtLabel);
        }
      });

      // Tracking arrow clicks
      $(document).on('mousedown touchdown', '.slick-prev,.slick-next, .samples__next, .samples__previous', function () {
        var $currentElem = $(this);
        var $samplePanel = $currentElem.closest('#samples-panel');
        var carouselName = '';
        var $checkoutPanelTitleElem = $currentElem.closest('.checkout__panel-content').siblings('.checkout__panel__header').find('.checkout__panel__title');
        var $samplesTitleElem = $currentElem.closest('.js-samples-content').siblings('.js-samples-title');
        var $toggleLinkTitleElem = $samplePanel.find('.js-toggle-link-title');

        if ($checkoutPanelTitleElem.length === 1) {
          carouselName = $checkoutPanelTitleElem.text().trim();
        } else if ($samplesTitleElem.length === 1) {
          carouselName = $samplesTitleElem.text().trim();
        } else if ($toggleLinkTitleElem.length === 1) {
          carouselName = $toggleLinkTitleElem.text().trim();
        } else {
          carouselName = $samplePanel.find('.samples__header-text').text().trim();
        }

        if ($samplePanel.length === 1) {
          trackEvent('sample_arrow_click', 'samples', 'arrow_click', carouselName);
        } else {
          trackEvent('checkout arrow click', 'ecommerce', 'arrow click', carouselName);
        }
      });

      // Sample Remove tracking
      $(document).on('click', '.sample-select-button, .js-sample-select-button', function () {
        var $currentElem = $(this);
        var $prodContainerElem = $currentElem.closest('.details, .product, .sample__description');
        var $targetElem = $prodContainerElem.length > 0 ? $prodContainerElem.first() : $currentElem;
        var $skuSelected = $targetElem.find('input.sample-select, input.sample-input, input.js-sample-select-option');
        var slectedSkuId = $skuSelected.length > 0 ? $skuSelected.first().val() : '';
        var $productNameElem = $targetElem.find('.product-name, .sample-name, .sample__name');
        var skuName = $productNameElem.length ? $productNameElem.first().text().trim() : '';
        var removeSample = $(this).hasClass('sample-select-button') ? !$skuSelected.prop('checked') : $currentElem.hasClass('selected');
        var $samplePageElem = $('body').attr('id') || $('body').attr('class');
        var separateSamplePage = ($samplePageElem && $samplePageElem.indexOf('sample') > -1) ? true : false;

        if ($currentElem.hasClass('js-sample-select-button') && postSampleSelection) {
          removeSample = !removeSample;
        }

        slectedSkuId = (slectedSkuId && slectedSkuId.toUpperCase().includes('SKU')) ? slectedSkuId : 'SKU' + slectedSkuId;
        if (removeSample) {
          trackEvent('samples', 'samples', 'samples_remove', skuName + ' - ' + slectedSkuId);
        } else if (separateSamplePage) {
          trackEvent('samples', 'samples', 'samples_added', skuName + ' - ' + skuId);
        }
      });

      self.attached = 1;
    }

  };
}(window.site || {}, jQuery));